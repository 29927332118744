import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import logo from "./../logo.svg";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBg: false,
      isNavOpen: false,
      key: Date.now(),
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  onClickHandler() {
    this.setState({
      isNavOpen: false,
      key: Date.now(),
    });
    window.scroll(0, 0);
  }

  handleScroll = () => {
    let scrollTop = window.scrollY,
      height = Math.min(0, scrollTop - 30);

    this.setState({
      isBg: height === 0 ? true : false,
    });
  };

  toggleNav() {
    let toggleStatus = this.state.isNavOpen;
    this.setState({
      isNavOpen: !toggleStatus,
      key: Date.now(),
    });
  }

  render() {
    return (
      <div
        className={`app__header row ${this.state.isBg ? "header__bg" : ""} ${this.state.isNavOpen ? "toggle__active" : ""
          }`}
      >
        <div>
          <NavLink onClick={this.onClickHandler} to="/home">
            <img src={logo} className="app__logo" alt="logo" />
          </NavLink>
          <div className="nav__toggle" onClick={this.toggleNav}>
            <div className="icon-burger">
              <div className="icon-burger__bar"></div>
              <div className="icon-burger__bar"></div>
              <div className="icon-burger__bar"></div>
            </div>
          </div>
        </div>
        <nav>
          <NavLink
            activeClassName="active__route"
            onClick={this.onClickHandler}
            to="/home"
          >
            Home
          </NavLink>

          <NavDropdown title="Calculators" id="basic-nav-dropdown">
            <NavDropdown.Item href="#loan-calculators">
              <NavLink
                activeClassName="active__route"
                onClick={this.onClickHandler}
                to="/loan-calculator"
              >
                Loan Calculator
              </NavLink>
            </NavDropdown.Item>
            <NavDropdown.Item href="#calculators">
              <NavLink
                activeClassName="active__route"
                onClick={this.onClickHandler}
                to="/loan-comparision-calculator"
              >
                Loan Comparision Calculator
              </NavLink>
            </NavDropdown.Item>
          </NavDropdown>

          <NavLink
            activeClassName="active__route"
            onClick={this.onClickHandler}
            to="/calendar"
          >
            Calendar
          </NavLink>

          <NavLink
            activeClassName="active__route"
            onClick={this.onClickHandler}
            to="/team"
          >
            Our Team
          </NavLink>

          <NavLink
            activeClassName="active__route"
            onClick={this.onClickHandler}
            to="/contact-us"
          >
            Contact Us
          </NavLink>
        </nav>
      </div>
    );
  }
}
