import React from "react";

export default class Loader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className={`loader__container ${this.props.loader ? 'loading' : ''}`}></div>
        )
    }
}
