import React from "react";
import { withRouter } from "react-router-dom";
import { Card, Form, Button, Modal } from "react-bootstrap";

import { LableTitle } from "./../component/LableTitle";
import { InputText } from "./../component/InputText";
import { InputRadio } from "./../component/InputRadio";

import LoanAPI from "./../actions/loan";
import Notification from "./../actions/notification";

class Loan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount:"",
      selectedFormIndex: 1,
      data: {},
      sections: [],
      answer: {},
      validated: false,
      showPopup: false,
      orderId: ''
    }

    this.toggleForm = this.toggleForm.bind(this)
    this.getFormClass = this.getFormClass.bind(this)
    this.setValue = this.setValue.bind(this)
    this.sendLead = this.sendLead.bind(this)
    this.hidePopup = this.hidePopup.bind(this)
  }

  async componentDidMount() {
    this.setState({
      data: this.props.location.state,
    });

    this.props.showLoader(true);

    let sections = await LoanAPI.getQuestions();

    sections = sections
      .map((sec) => {
        sec.questions = sec.questions
          .map((que) => {
            que["value"] = "";
            return que;
          })
          .sort((a, b) => a["order"] - b["order"]);
        return sec;
      })
      .sort((a, b) => a["order"] - b["order"]);

    this.setState({
      sections: sections,
    });

    this.props.showLoader(false);
  }

  toggleForm(value) {
    this.setState({
      selectedFormIndex: value,
    });
  }

  getFormClass(index) {
    let className = ["form__box"];

    if (index < this.state.sections.length - 1) {
      className.push("form__indicator");
    }

    if (this.state.sections[index].questions.every((q) => q.value)) {
      className.push("complete__form");
    } else {
      className.push("incomplete__form");
    }

    if (this.state.selectedFormIndex === index + 1) {
      className.push("active__form");
    }

    return className.join(" ");
  }

  setValue(state, value) {
    this.setState({
      [state]: value,
    });
  }

  async sendLead(event) {
    event.preventDefault();
    event.stopPropagation();

    let form = event.currentTarget;

    if (form.checkValidity() === false) {
      Notification.Warn("Required", "Please answer all the questions.");
    } else {
      let lead = this.state.sections.reduce((lead, item) => {
        item.questions.forEach((que) => {
          if (que.type === "radio") {
            lead.push({
              question: que._id,
              answer: que.value,
              value: "",
            });
          } else {
            lead.push({
              question: que._id,
              answer: que.value ? que.answers[0]._id : "",
              value: que.value || "",
            });
          }
        });
        return lead;
      }, []);
      this.props.showLoader(true);

      let sendLeadResponse = await LoanAPI.sendLead(lead, this.props.location.state);
      if (sendLeadResponse) {
        if (sendLeadResponse.data && sendLeadResponse.data["order_id"]) {
          this.setState({
            showPopup: true,
            orderId: sendLeadResponse.data["order_id"]
          })
        } else {
          Notification.Error("Error:", "Something wrong.");
        }

        this.props.showLoader(false);
      }
    }
    this.setValue("validated", true);
  }

  hidePopup() {
    this.props.history.push("/home");
  }

  render() {
    return (
      this.state.sections.length > 0 && (
        <div className="app__container loan__page">
          <div className="medium__text">New Loan</div>

          <Form
            onSubmit={this.sendLead}
            noValidate
            validated={this.state.validated}
          >
            <div className="row m-0">
              {this.state.sections.map((sec, secIndex) => {
                return (
                  <div
                    key={`section_${secIndex}`}
                    className={`${this.getFormClass(
                      secIndex
                    )} col-sm-12 col-md-6 col-lg-6`}
                  >
                    <LableTitle
                      lable={secIndex + 1}
                      title={sec.title}
                      onClick={() => this.toggleForm(secIndex + 1)}
                    />
                    <Card>
                      {sec.questions.map((que, queIndex) => {
                        switch (que.type) {
                          case "text":
                            return (
                              <InputText
                                key={`question_${secIndex}_${queIndex}`}
                                type="text"
                                title={que.question}
                                label={que.answers[0].label}
                                errorMessage={`Please ${que.answers[0].label}`}
                                setValue={(value) => (que.value = value)}
                                pattern={"^[a-zA-Z .]{3,}$"}
                              />
                            );

                          case "email":
                            return (
                              <InputText
                                key={`question_${secIndex}_${queIndex}`}
                                type="email"
                                title={que.question}
                                label={que.answers[0].label}
                                errorMessage={`Please ${que.answers[0].label}`}
                                setValue={(value) => (que.value = value)}
                              />
                            );

                          case "phone":
                            return (
                              <InputText
                                key={`question_${secIndex}_${queIndex}`}
                                type="phone"
                                title={que.question}
                                label={que.answers[0].label}
                                errorMessage={`Please ${que.answers[0].label}`}
                                prepend="+65"
                                setValue={(value) => (que.value = value)}
                                pattern={"(6|8|9){1}[0-9]{7}"}
                              />
                            );

                          case "amount":
                            return (
                              <InputText
                                key={`question_${secIndex}_${queIndex}`}
                                type="text"
                                title={que.question}
                                label={que.answers[0].label}
                                errorMessage={`Please ${que.answers[0].label}`}
                                prepend="S$."
                                value={this.state.amount}
                                setValue={(value) => this.setValue("amount", que.value = value)}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }}}
                              />
                            );

                          case "radio":
                            return (
                              <InputRadio
                                key={`question_${secIndex}_${queIndex}`}
                                id={`question_${secIndex}_${queIndex}`}
                                title={que.question}
                                options={que.answers}
                                setValue={(value) => (que.value = value)}
                              />
                            );

                          default:
                            return <div></div>;
                        }
                      })}
                    </Card>
                  </div>
                );
              })}
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center mt-4">
                <Button variant="success" type="submit">
                  Get Quote
                </Button>
              </div>
            </div>
          </Form>

          <Modal
            show={this.state.showPopup}
            onHide={this.hidePopup}
            size="xl"
            scrollable={true}
          >
            <Modal.Body className="success_popup">
              <div className="__title">
                Thank you for sharing your details!
              </div>
              <div className="__subtitle">
                Order ID : {this.state.orderId}
              </div>
              <div className="__message">
                Our expert will contact you soon to give you best service.
                Your reference ID is {this.state.orderId} and the same has been sent you via email and SMS as well.
              </div>
              <Button
                variant="success"
                onClick={this.hidePopup}
              >
                Close
              </Button>
            </Modal.Body>
          </Modal>

        </div>
      )
    );
  }
}

export default withRouter(Loan);
