import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import Header from "./component/Header";
import Footer from "./component/Footer";
import Loader from "./component/Loader";

import Home from "./pages/Home";
import NewLoanCalculator from "./pages/Calculators/NewLoan";
import RefinancingCalculator from "./pages/Calculators/Refinancing";
import LoanComparisionCalculator from "./pages/Calculators/LoanComparisionCalculator";
import LoanCalculator from "./pages/Calculators/LoanCalculator";
import Loan from "./pages/Loan";
import Calender from "./pages/Calender";
import Team from "./pages/Team";
import ContactUs from "./pages/ContactUs";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PDPATermsAndConditions from "./pages/PDPATermsAndConditions";

import "./App.css";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
    };

    this.showLoader = this.showLoader.bind(this);
  }

  showLoader(status) {
    this.setState({
      loader: status,
    });
  }

  render() {
    return (
      <div className="App" id="App">
        <BrowserRouter>
          <Header />
          <ReactNotification />
          <Loader loader={this.state.loader} />
          <Switch>
            <Route path="/home">
              <Home showLoader={this.showLoader} />
            </Route>
            <Route path="/calculators/new_loan">
              <NewLoanCalculator showLoader={this.showLoader} />
            </Route>
            <Route path="/calculators/refinancing">
              <RefinancingCalculator showLoader={this.showLoader} />
            </Route>
            <Route path="/loan-calculator">
              <LoanCalculator showLoader={this.showLoader} />
            </Route>
            <Route path="/loan-comparision-calculator">
              <LoanComparisionCalculator showLoader={this.showLoader} />
            </Route>
            <Route path="/loan">
              <Loan showLoader={this.showLoader} />
            </Route>
            <Route path="/calendar">
              <Calender showLoader={this.showLoader} />
            </Route>
            <Route path="/team">
              <Team showLoader={this.showLoader} />
            </Route>
            <Route path="/contact-us">
              <ContactUs showLoader={this.showLoader} />
            </Route>
            <Route path="/terms-of-use">
              <TermsOfUse showLoader={this.showLoader} />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy showLoader={this.showLoader} />
            </Route>
            <Route path="/PDPA-terms-and-conditions">
              <PDPATermsAndConditions showLoader={this.showLoader} />
            </Route>
            <Route path="/">
              <Home showLoader={this.showLoader} />
            </Route>
          </Switch>
          <Footer />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
