import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import Scroll from 'react-scroll'

import "react-calendar/dist/Calendar.css";

import Notification from "../../actions/notification";
import { InputText } from "../../component/InputText";

const Element = Scroll.Element;
const scroller = Scroll.scroller;

class LoanComparisionCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      term1: "",
      interest1: "",
      term2: "",
      interest2: "",

      hasResult: false,

      MonthlyPayment_1: "",
      TotalPayment_1: "",
      TotalInterest_1: "",
      AnnualPayment_1: "",
      InterestPaid_1: "",
      Saving_1: "",

      MonthlyPayment_2: "",
      TotalPayment_2: "",
      TotalInterest_2: "",
      AnnualPayment_2: "",
      InterestPaid_2: "",
      Saving_2: "",

      validated: false
    };
    this.calculate = this.calculate.bind(this);
    this.setValue = this.setValue.bind(this);
  }

  async calculate(event) {
    event.preventDefault();
    event.stopPropagation();

    let k=this.state.amount
    k=k.replace(/\,/g,'');
    k=parseInt(k,10)

    let form = event.currentTarget;

    if (form.checkValidity() === false) {
      Notification.Warn("Required", "Please fill all fields.");
    } else {
      let interest1 = (k * this.state.interest1 * this.state.term1) / 100
      let interest2 = (k * this.state.interest2 * this.state.term2) / 100

      this.setState({
        hasResult: true,

        MonthlyPayment_1: this.formatNumber((((+k + +interest1) / this.state.term1) / 12)),
        TotalPayment_1: this.formatNumber((+k + +interest1)),
        TotalInterest_1: this.formatNumber(interest1),
        AnnualPayment_1: this.formatNumber(((+k + +interest1) / this.state.term1)),
        InterestPaid_1: this.formatNumber(interest1),
        Saving_1: this.formatNumber(""),

        MonthlyPayment_2: this.formatNumber((((+k + +interest2) / this.state.term2) / 12)),
        TotalPayment_2: this.formatNumber((+k + +interest2)),
        TotalInterest_2: this.formatNumber(interest2),
        AnnualPayment_2: this.formatNumber(((+k + +interest2) / this.state.term2)),
        InterestPaid_2: this.formatNumber(interest2),
        Saving_2: this.formatNumber(interest1 - interest2)
      })
      scroller.scrollTo('myScrollToElement', {
        duration: 500,
        delay: 0,
        smooth: true,
        offset: -150
      })
    }
    this.setValue("validated", true);
  }

  setValue(state, value) {
    this.setState({
      [state]: value,
    })
  }

  formatNumber(value) {
    try {
      return value.toFixed(2).replace(/\.00$/, '')
    } catch (e) {
      return value
    }
  }

  render() {
    return (
      <div className="app__container">
        <div className="medium__text">Loan Comparision Calculator</div>
        <Form
          onSubmit={this.calculate}
          noValidate
          validated={this.state.validated}
        >
          <div className="input__group row">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <InputText
                type="text"
                label="Enter Loan Amount"
                min={0}
                value={this.state.amount}
                errorMessage={`Please Enter Loan Amount`}
                setValue={(value) => this.setValue("amount", value)}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }}}
              />
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8"></div>

            <div className="col-sm-12 col-md-4 col-lg-4">
              <InputText
                type="number"
                label="Enter Loan Term 1"
                postpend="YEARS"
                step={1}
                min={10}
                max={30}
                errorMessage={`Please Enter Loan term (Min:10, Max:30)`}
                setValue={(value) => this.setValue("term1", value)}
              />
            </div>
            <div className="col-sm-12 col-md-1 col-lg-1"></div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <InputText
                type="number"
                label="Enter Interest (%)"
                min={0}
                step={0.01}
                errorMessage={`Please Enter Interest (%)`}
                setValue={(value) => this.setValue("interest1", value)}
              />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3"></div>

            <div className="col-sm-12 col-md-4 col-lg-4">
              <InputText
                type="number"
                label="Enter Loan Term 2"
                postpend="YEARS"
                step={1}
                min={10}
                max={30}
                errorMessage={`Please Enter Loan term (Min:10, Max:30)`}
                setValue={(value) => this.setValue("term2", value)}
              />
            </div>
            <div className="col-sm-12 col-md-1 col-lg-1"></div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <InputText
                type="number"
                label="Enter Interest (%)"
                min={0}
                step={0.01}
                errorMessage={`Please Enter Interest (%)`}
                setValue={(value) => this.setValue("interest2", value)}
              />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3"></div>

          </div>

          <div className="row">
            <div className="col-sm-12 col-md-2 col-lg-2"></div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Button
                variant="outline-success"
                onClick={() => this.setValue('hasResult', false)}
              >
                Reset
              </Button>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Button
                variant="success"
                type="submit"
              >
                Calculate
              </Button>
            </div>
            <div className="col-sm-12 col-md-2 col-lg-2"></div>
          </div>
        </Form>
        <Element name="myScrollToElement">
          {
            this.state.hasResult
            && <div className="row mt-4">
              <div className="col-sm-12 col-md-1 col-lg-1"></div>
              <div className="col-sm-12 col-md-10 col-lg-10 mt-4">
                <div className="card mt-4 mb-4 text-align-center">
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4"></div>
                    <div className="col-sm-12 col-md-4 col-lg-4 __title mb-4">Loan Term 1</div>
                    <div className="col-sm-12 col-md-4 col-lg-4 __title mb-4">Loan Term 2</div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 __title">
                      <div className="title__box">Monthly Payment :</div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 __title__value">{this.state.MonthlyPayment_1}</div>
                    <div className="col-sm-12 col-md-4 col-lg-4 __title__value">{this.state.MonthlyPayment_2}</div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 __title">
                      <div className="title__box">Total Payment :</div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 __title__value">{this.state.TotalPayment_1}</div>
                    <div className="col-sm-12 col-md-4 col-lg-4 __title__value">{this.state.TotalPayment_2}</div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 __title">
                      <div className="title__box">Total Interest :</div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 __title__value">{this.state.TotalInterest_1}</div>
                    <div className="col-sm-12 col-md-4 col-lg-4 __title__value">{this.state.TotalInterest_2}</div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 __title">
                      <div className="title__box">Annual Payment :</div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 __title__value">{this.state.AnnualPayment_1}</div>
                    <div className="col-sm-12 col-md-4 col-lg-4 __title__value">{this.state.AnnualPayment_2}</div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4"></div>
                    <div className="col-sm-12 col-md-4 col-lg-4 __title __theme__color mb-4 mt-4">Total Savings</div>
                    <div className="col-sm-12 col-md-4 col-lg-4"></div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 __title">
                      <div className="title__box">Interest Paid :</div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 __title__value">{this.state.InterestPaid_1}</div>
                    <div className="col-sm-12 col-md-4 col-lg-4 __title__value">{this.state.InterestPaid_2}</div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 __title">
                      <div className="title__box">Savings with New Loan :</div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 __title__value">{this.state.Saving_1}</div>
                    <div className={`col-sm-12 col-md-4 col-lg-4 __title__value ${this.state.Saving_2 < 0 ? '__alert__color' : '__yellow__color'}`}>{this.state.Saving_2}</div>
                  </div>

                </div>
              </div>
            </div>
          }
        </Element>
      </div>
    );
  }
}
export default LoanComparisionCalculator;
