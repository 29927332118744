import axios from './../axios';

const getBanks = async () => {
    return axios({
        method: 'POST',
        url: '/banks/get'
    })
        .then(response => response.data)
        .then(response => {
            return response.data.response
        })
        .catch(error => {
            return []
        })
}

let actions = {
    getBanks
}

export default actions
