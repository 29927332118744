import React, { Component } from "react";

export class LableTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="form__lable" onClick={this.props.onClick}>
                {
                    this.props.lable
                    && (
                        <div className="lable__count">{this.props.lable}</div>
                    )
                }
                <div className="lable__title">{this.props.title}</div>
            </div>
        )
    }
}
