const calculate = (principal, rate, year, month) => {
    let interest = 0,
        installment = 0,
        payment = 0;

    principal = +principal
    rate = +rate
    year = +year
    month = +month

    if (principal > 0 && rate >= 0 && year >= 0 && month >= 0) {

        let balance = principal
            , interestRate = rate / 100.0
            , terms = (year * 12) + month
            , monthlyRate = interestRate / 12
            , monthltPayment = balance * (monthlyRate / (1 - Math.pow(1 + monthlyRate, -terms)))
            , amortization = [];

        amortization.push([
            '',
            '',
            '',
            '',
            balance.toFixed(2)
        ])
        for (let count = 0; count < terms; ++count) {
            let interest = balance * monthlyRate
                , monthlyPrincipal = monthltPayment - interest;

            balance = balance - monthlyPrincipal

            if (balance < 0) {
                balance = 0
            }

            amortization.push([
                count + 1,
                monthltPayment.toFixed(2),
                interest.toFixed(2),
                monthlyPrincipal.toFixed(2),
                balance.toFixed(2)
            ])

        }

        payment = monthltPayment * terms
        interest = payment - principal
        installment = monthltPayment
    }

    return {
        interest: formatNumber(interest),
        installment: formatNumber(installment),
        payment: formatNumber(payment)
    }
}

const formatNumber = (value) => {
    try {
        return isFinite(value) ? value.toFixed(2).replace(/\.00$/, '') : 0
    } catch (e) {
        return value
    }
}

let actions = {
    calculate
}

export default actions
