import React from "react";
import { withRouter } from "react-router-dom";
import { Form, Button, Modal } from "react-bootstrap";

import { InputText } from "../component/InputText";

import Notification from "../actions/notification";
import ContactUsAPI from "./../actions/contactUs";

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phoneNo: "",
      message: "",
      validated: false,
      showPopup: false
    };
    this.send = this.send.bind(this)
    this.setValue = this.setValue.bind(this)
    this.hidePopup = this.hidePopup.bind(this)
  }

  async send(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.showLoader(true);
    let form = event.currentTarget;

    if (form.checkValidity() === false) {
      this.setValue("validated", true);
      Notification.Warn("Required", "Please fill all fields.");
    } else {
      let data = {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phoneNo,
        message: this.state.message
      };

      let calenderAdd = await ContactUsAPI.send(data);

      if (calenderAdd.data) {
        this.setState({
          name: '',
          email: '',
          phone: '',
          message: '',
          validated: false
        })
        this.setState({
          showPopup: true
        })
      } else {
        Notification.Warn("Error", `${calenderAdd.message}`);
      }
    }
    this.props.showLoader(false);
  }

  setValue(state, value) {
    this.setState({
      [state]: value,
    });
  }

  hidePopup() {
    this.props.history.push("/home");
  }

  render() {
    return (
      <div className="app__container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <Form
              onSubmit={this.send}
              noValidate
              validated={this.state.validated}
            >
              <div className="medium__text">Get In Touch</div>
              <div className="card">
                <InputText
                  type="text"
                  label="Enter Name"
                  errorMessage={`Please Enter Name`}
                  value={this.state.name}
                  setValue={(value) => this.setValue("name", value)}
                  // onChange={e=>this.setState({
                  //   name:name.trim()
                  // })}
                />
                <InputText
                  type="text"
                  label="Enter Email Address"
                  errorMessage={`Please Enter Email Address`}
                  value={this.state.email}
                  setValue={(value) => this.setValue("email", value)}
                />
                <InputText
                  type="text"
                  label="Enter Phone Number"
                  errorMessage={`Please Enter Phone Number`}
                  value={this.state.phoneNo}
                  setValue={(value) => this.setValue("phoneNo", value)}
                />
                <InputText
                  type="textarea"
                  label="Enter Message"
                  errorMessage={`Please Enter Message`}
                  value={this.state.message}
                  setValue={(value) => this.setValue("message", value)}
                />
                <InputText
                  type="checkbox"
                  label="PDPA Terms And Conditions*"
                  href="/PDPA-terms-and-conditions"
                  errorMessage={`Please Accept This`}
                  value={this.state.message}
                  setValue={(value) => this.setValue("message", value)}
                />
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center mt-4">
                    <Button variant="success" type="submit">
                      Send
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <div className="col-sm-12 col-md-1 col-lg-1"></div>
          <div className="col-sm-12 col-md-5 col-lg-5">
            <div className="medium__text mobile__margin">Reach Us By</div>
            <div className="contact__box">
              <div className="contact__title">Address</div>
              <div className="contact__value">
                <div>60, Paya Lebar Road, #12-14,</div>
                <div>Paya Lebar Square, Singapore 409051</div>
              </div>
            </div>
            <div className="contact__box">
              <div className="contact__title">Phone</div>
              <div className="contact__value">+65 8300 2255</div>
            </div>
            <div className="contact__box">
              <div className="contact__title">Email</div>
              <div className="contact__value">enquiry@loanonline.sg</div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showPopup}
          onHide={this.hidePopup}
          size="xl"
          scrollable={true}
        >
          <Modal.Body className="success_popup">
            <div className="__title">
              Thank you for contacting us!
              </div>
            <div className="__message">
              Our expert will contact you soon to give you best service.
              </div>
            <Button
              variant="success"
              onClick={this.hidePopup}
            >
              Close
              </Button>
          </Modal.Body>
        </Modal>
      </div >
    );
  }
}

export default withRouter(ContactUs);
