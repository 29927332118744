import React, { Component } from "react";
import Calendar from "react-calendar";
import { Form, Button, Modal } from "react-bootstrap";
import "react-calendar/dist/Calendar.css";

import { LableTitle } from "../component/LableTitle";
import { InputText } from "./../component/InputText";
import { InputSelect } from "./../component/InputSelect";

import addRemainder from "../actions/calender";
import Notification from "./../actions/notification";

class Calender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      contact_no: "",
      email: "",
      period: 3,
      start_date: "",
      validated: false,
      options: [
        { val: "0" },
        { val: "1" },
        { val: "2" },
        { val: "3" },
        { val: "4" },
        { val: "5" },
        { val: "6" }
      ],
      showPopup: false
    }

    this.addCalender = this.addCalender.bind(this)
    this.setValue = this.setValue.bind(this)
    this.hidePopup = this.hidePopup.bind(this)
  }

  async addCalender(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.showLoader(true);
    let form = event.currentTarget;

    if (form.checkValidity() === false) {
      Notification.Warn("Required", "Please fill all fields.");
    } else {
      let data = {
        name: this.state.name,
        contact_no: this.state.contact_no,
        email: this.state.email,
        period: this.state.period,
        start_date: this.state.start_date,
      };

      let calenderAdd = await addRemainder.addRemainderCalender(data);

      if (calenderAdd.data) {
        this.setValue("showPopup", true);
      } else {
        Notification.Warn("Error", `${calenderAdd.message}`);
      }
    }
    this.setValue("validated", true);
    this.props.showLoader(false);
  }

  setValue(state, value) {
    this.setState({
      [state]: value,
    })
  }

  hidePopup() {
    this.setState({
      showPopup: false
    })
  }

  render() {
    return (
      <div className="app__container">

        <Form
          onSubmit={this.addCalender}
          noValidate
          validated={this.state.validated}
        >
          <div className="medium__text">Mortgage Reminder Calendar</div>
          <div className="row">
            <div className="col-sm-12 col-md-5 col-lg-5">
              <LableTitle title="Select your loan start date" />
              <div className="d-flex justify-content-center">
                <Calendar onChange={(e) => this.setValue("start_date", e)} />
              </div>
            </div>
            <div className="col-sm-12 col-md-1 col-lg-1"></div>
            <div className="col-sm-12 col-md-5 col-lg-5">
              <InputText
                type="text"
                title="How can we reach you via email? *"
                label="Enter Email"
                errorMessage={`Please Enter Email`}
                setValue={(value) => this.setValue("email", value)}
              />
              <InputText
                type="text"
                title="How do we address you? *"
                label="Enter Name"
                errorMessage={`Please Enter Name`}
                setValue={(value) => this.setValue("name", value)}
                onChange={e=>this.setState({
                  name:e.target.value.trim()
                })}
              />
              <InputText
                type="text"
                title="How can we reach you via phone? *"
                label="Enter Phone Number"
                errorMessage={`Please Enter Phone Number`}
                setValue={(value) => this.setValue("contact_no", value)}
              />
              <InputSelect
                title={"What is your loan lock in period? *"}
                label="Lock In Period (Years)"
                options={this.state.options}
                value={this.state.period}
                setValue={(value) => this.setValue("period", value)}
              />
              {/* <Form.Group>
                <Form.Label>Lock In period</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  name="period"
                  value={this.state.period}
                  onChange={(e) => this.setValue("period", e.target.value)}
                >
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Form.Control>
              </Form.Group> */}
            </div>
            <div className="col-sm-12 col-md-1 col-lg-1"></div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center mt-5">
              <InputText
                type="checkbox"
                label="PDPA Terms And Conditions*"
                href="/PDPA-terms-and-conditions"
                errorMessage={`Please Accept This`}
                value={this.state.message}
                setValue={(value) => this.setValue("message", value)}
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center">
              <Button variant="success" type="submit">
                Submit
              </Button>
            </div>
          </div>
        </Form>

        <Modal
          show={this.state.showPopup}
          onHide={this.hidePopup}
          size="xl"
          scrollable={true}
        >
          <Modal.Body className="success_popup">
            <div className="__title">
              Now you can relax!
            </div>
            <div className="__message">
              Your Reminder has been set! We will remind you by sending Email and SMS at appropriate time 3 months before your Loan Tenure gets over.
            </div>
            <Button
              variant="success"
              onClick={this.hidePopup}
            >
              Close
              </Button>
          </Modal.Body>
        </Modal>

      </div>
    );
  }
}
export default Calender;
