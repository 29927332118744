import React, { Component } from "react";
import { Form, InputGroup } from "react-bootstrap";

export class InputText extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      this.props.type === 'checkbox'
        ? (
          <Form.Group>
            <div className="form-check">
              <input type="checkbox" className="form-check-input" required />
              <label className="form-check-label">
                {
                  this.props.href
                    ? (
                      <a
                        href={this.props.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {this.props.label}
                      </a>
                    )
                    : (
                      this.props.label
                    )
                }
              </label>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {this.props.errorMessage}
              </Form.Control.Feedback>
            </div>
          </Form.Group>
        )
        : (
          <Form.Group>
            <div className="formgroup__title">{this.props.title}</div>
            <Form.Label>{this.props.label}</Form.Label>
            <InputGroup>
              {
                this.props.prepend && (
                  <InputGroup.Prepend>
                    <InputGroup.Text>{this.props.prepend}</InputGroup.Text>
                  </InputGroup.Prepend>
                )
              }
              {
                this.props.type === 'textarea'
                  ? <Form.Control
                    pattern={this.props.pattern}
                    as={this.props.type}
                    rows={3}
                    value={this.props.value}
                    onChange={(e) => this.props.setValue(e.target.value)}
                    required
                  />
                  : this.props.type === 'number'
                    ? <Form.Control
                      pattern={this.props.pattern}
                      type={this.props.type}
                      id={this.props.id}
                      value={this.props.value}
                      min={this.props.min}
                      max={this.props.max}
                      step={this.props.step}
                      onInput={(e) => {
                        let t = e.target.value;
                        // let value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 2)) : t;
                        let value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
                        this.props.setValue(value)
                      }}
                      onKeyPress={this.props.onKeyPress}
                      onKeyUp={this.props.onKeyUp}
                      pattern={this.props.pattern}
                      onChange={this.props.onChange}
                      required
                    />
                    : this.props.type === 'phone'
                      ? <Form.Control
                        pattern={this.props.pattern}
                        minlength="8"
                        maxlength="8"
                        type="tel"
                        value={this.props.value}
                        onChange={(e) => this.props.setValue(e.target.value)}
                        required
                      />
                      : <Form.Control
                        pattern={this.props.pattern}
                        type={this.props.type}
                        value={this.props.value}
                        onKeyPress={this.props.onKeyPress}
                        // onChange={(e) => this.props.setValue(e.target.value)}
                        onInput={(e) => {
                          let t = e.target.value;
                          t = (parseInt(t.replace(/[^\d]+/gi, '')) || 0).toLocaleString('en-US')
                          this.props.setValue(t)
                        }}
                        required
                      />
              }
              {
                this.props.postpend && (
                  <InputGroup.Prepend>
                    <InputGroup.Text>{this.props.postpend}</InputGroup.Text>
                  </InputGroup.Prepend>
                )
              }
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {this.props.errorMessage.toLowerCase()}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group >
        )
    );
  }
}
