import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export class ImageInputSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 3
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2
        }
      }
    }
  }

  render() {

    return (
      <div className="input__group row" >
        <div className="col-lg-6 col-md-6 col-sm-12 __title">
          {this.props.title}
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12"></div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <Carousel
            responsive={this.state.responsive}
            swipeable={true}
          >
            {
              this.props.images.map((image, index) => {
                return (
                  <div
                    key={`${this.props.title}_${index}`}
                    className={`slider_image_container ${+this.props.value === index ? 'active_image' : ''}`}
                    onClick={() => this.props.setValue(index)}
                  >
                    <img src={image.logo} alt={image.name} />
                  </div>
                )
              })
            }
          </Carousel>
          {/* <div className="image_viewer d-flex justify-content-between">
            {
              this.props.images.map((image, index) => {
                return (
                  <div
                    key={`${this.props.title}_${index}`}
                    className={`slider_image_container ${this.state.selectedIndex === index ? 'active_image' : ''}`}
                    onClick={() => this.props.setValue(index)}
                  >
                    <img src={image.src} alt={image.title} />
                  </div>
                )
              })
            }
          </div> */}
        </div>
      </div >
    )
  }
}
