import axios from "axios";

let axiosInstance = axios.create({
  // baseURL: "http://localhost:3000/lol"
  baseURL: "https://api.proplify.com/lol"
  // baseURL: 'https://node45079-env-5186133.cloudjiffy.net/lol'
});

// axiosInstance.interceptors.request.use((config) => {
//   config.headers.Authorization = localStorage.getItem("token");
//   return config;
// });

export default axiosInstance;
