import React, { Component } from "react";
import { Form } from 'react-bootstrap'

export class InputRadio extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Form.Group>
                {
                    this.props.title
                    && <div className="formgroup__title">{this.props.title}</div>
                }
                {
                    this.props.label
                    && <Form.Label>{this.props.label}</Form.Label>
                }
                {
                    this.props.options
                        .map((option, index) => {
                            return (
                                <Form.Check
                                    key={index}
                                    type="radio"
                                    id={`${this.props.id}_${index}`}
                                    name={this.props.id}
                                    label={option.answer}
                                    onChange={(e) => this.props.setValue(option._id)}
                                    required
                                />
                            )
                        })
                }
            </Form.Group>

        )
    }
}
