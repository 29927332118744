import React, { Component } from "react"

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="app__container">
        <div className="medium__text">Privacy Policy</div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="team_text mt-5">
              This Privacy Policy describes how LOL Interactive Pte Ltd and its holding company or
              companies, subsidiaries and subsidiaries of its holding company or companies (collectively
              “LoanOnline”, “we”, “us”, “our”) handle your personal data.
            </div>
            <div className="team_text mt-3">
              <b>Protecting You</b>
            </div>
            <div className="team_text mt-3">
              The LoanOnline website www.LoanOnline.sg (“Website”), LoanOnline mobile application (“App”)
              and LoanOnline Facebook (“Page”) are owned and operated by LoanOnline. This Policy applies to
              you if you browse, access or use the Website, or download, install or using the App and Page. Where
              this Policy uses a word starting with a capital letter which is not otherwise defined, that word will
              have the same meaning as used in the terms and conditions of the Website and/or App and Page.
            </div>
            <div className="team_text mt-3">
              This Policy explains what personal data we collect, how we use and/or share this personal data,
              and how such personal data is maintained by us. By browsing, accessing or using this Website,
              or by downloading, installing or using this App and Page, you signify your acceptance of this Policy. If
              you do not agree to the terms of this Policy, whether in whole or part, you should not apply to
              use or use this Website or App and Page.
            </div>
            <div className="team_text mt-3">
              Please read this Privacy Policy carefully and check back periodically as we update it from time
              to time. Each time you use the App, Page and/or Website, you signify your agreement to the latest
              version of this Privacy Policy that is in force.
            </div>
            <div className="team_text mt-3">
              <b>Information We Collect</b>
            </div>
            <div className="team_text mt-3">
              Personal data means any data about an individual who can be identified from that data. It
              includes without limitation an individual’s name, identity card, passport or other identification
              number, telephone numbers, address and email address.
            </div>
            <div className="team_text mt-3">
              We collect personal data that is volunteered by you when you use this Website or App and Page,
              such as when you apply for a User Account or respond to specific information requests explicitly
              presented to you by LoanOnline. Depending on what you use this Website or App and Page for,
              LoanOnline may require additional information, such as billing information, including details of your
              chosen payment mechanism, such as credit card details). LoanOnline automatically receives and
              records some information from your browser or mobile phone when you visit or use this Website or
              App and Page, such as your IP (Internet Protocol) address, cookies and data about which pages you
              visit on the site through the use of log files provided by our thirdparty tracking-utility partners. This
              information helps us analyze and understand how the site works for our customers. We may also
              collect your IP address to help diagnose problems with our server, and to administer this Website or
              App and Page. An IP address is a number that is assigned to your computer when you use the
              Internet. Your IP address is also used to help identify you during a particular session and to gather
              broad demographic data. If you connect to or register for a User Account for this Website or App and
              Page by using an external thirdparty application, such as Facebook, or an app developed using the
              LoanOnline API, LoanOnline may receive some data from those connected third-party applications.
              Connecting your LoanOnline account to third-party applications or services is optional. You can
              revoke your permission to connect your account to the external app by changing your LoanOnline
              account settings.
            </div>
            <div className="team_text mt-3">
              LoanOnline may also collect personal data from you by the following means:
            </div>
            <div className="team_text mt-3">
              <ul>
                <li>From application or registration forms or other similar forms submitted to LoanOnline;</li>
                <li>From publicly available sources such as telephone directories;</li>
                <li>From LoanOnline’s social media pages, if you follow, like or are a fan of such pages;</li>
                <li>From credit reporting agencies;</li>
                <li>When you interact and communicate with LoanOnline at any events or activities;</li>
                <li>When you enter contests organized by LoanOnline;</li>
                <li>From various entities or divisions under LoanOnline;</li>
                <li>From your use of other websites operated by LoanOnline; and</li>
                <li>From cookies used on our websites.</li>
              </ul>
            </div>
            <div className="team_text mt-3">
              <b>How We Use Information</b>
            </div>
            <div className="team_text mt-3">
              We will use the personal data that we collect about you lawfully (in accordance with the Data
              Protection Act 2012 (the “Applicable Law”)). LoanOnline uses personal data collected from you for
              the business and activities of LoanOnline which shall include without limitation, the following:
            </div>
            <div className="team_text mt-3">
              <ul>
                <li>for identification and authentication purposes;</li>
                <li>to perform the LoanOnline’s obligations in respect of any contract entered into with you; to
provide you with any service you have requested;</li>
                <li>where you have requested to download and use the App and Page to process your request, to
deliver the App and Page to you and to provide you a license for the use of the App;</li>
                <li>to process your participation in any events, activities, focus groups, research studies,
                contests, promotions, polls, surveys or any productions and to communicate with you regarding
your attendance thereto;</li>
                <li>to process, manage or verify your application for a User Account and to provide you the benefits
offered to User Account holders;</li>
                <li>to validate and process payments for products or services you have requested;</li>
                <li>to understand and analyze our sales as well as your needs and preferences;</li>
                <li>to develop, enhance and provide products and services to meet your needs;</li>
                <li>to facilitate or enable any checks as may be required pursuant to the Terms;</li>
                <li>to respond to questions, comments and feedback from you;</li>
                <li>where you are a User Account holder or where you have requested for it, to send you alerts,
                newsletters, updates, mailers, promotional materials and notices of special privileges and festive
greetings from LoanOnline, its partners, advertisers and/or sponsors;</li>
                <li>where you are a User Account holder or where you have requested for it, to notify and invite
you to events or activities organized by LoanOnline, its partners, advertisers, and/or sponsors;</li>
                <li>to respond to questions, comments and feedback from you;</li>
                <li>to communicate with you for any of the purposes listed in this Policy;</li>
                <li>for internal administrative purposes, such as auditing, data analysis, database records and
reporting purposes within the LoanOnline group of companies;</li>
                <li>for purposes of detection, prevention and prosecution of crime;</li>
                <li>for LoanOnline to comply with its obligations under the Applicable Law;</li>
                <li>to respond to governmental enquiries (or enquiries from a legal, governmental or
quasigovernmental or local authority agency or Internet protection agency of any type);</li>
                <li>to comply with a valid legal process or procedure; and</li>
                <li>to protect our rights or property, this Website or App and Page, and/or other users of this Website
                or App and Page. LoanOnline may also collect, use and disclose your personal data for any other
purpose which is permitted or required under the Applicable Law.</li>
              </ul>
            </div>
            <div className="team_text mt-3">
              <b>Information We Share</b>
            </div>
            <div className="team_text mt-3">
              A variety of third party merchants may offer promotions or sell products or services to you
              through this Website or App and Page. LoanOnline may work with such merchants to offer you the
              chance of purchase vouchers for their products and services on this Website or App and Page. Due
              to the nature of our collaboration model, if you purchase a voucher or otherwise enter into a
              transaction with the merchant through this Website or App and Page, we may share information
              that is related to such transaction with the merchant in order for it to provide you with the products
              or services that you have requested for, or in order to achieve any of the other purposes set out in
              this Policy. LoanOnline may also share aggregated demographic information with such merchants,
              who are our business partners. LoanOnline may release your personal data to a third-party in order
              comply with a subpoena or other similar legal procedure, or when we believe in good faith that such
              disclosure is necessary to comply with the Applicable Law; prevent imminent physical harm or
              financial loss; or investigate or take action regarding illegal activities, suspected fraud, or violations
              of LoanOnline’s Terms.
            </div>
            <div className="team_text mt-3">
              We may disclose personal data to third parties, as we in our sole discretion believe necessary
              or appropriate, in connection with an investigation of fraud, intellectual property infringement,
              piracy, or other unlawful activity. In such events, LoanOnline may disclose name, address, country,
              phone number, email address and company name.
            </div>
            <div className="team_text mt-3">
              LoanOnline employs agents, subcontractors, service providers to perform tasks on our behalf, and
              we need to share your personal data with them to provide products and services to you or to
              otherwise achieve any of the other purposes set out in this Policy. Examples include but are not
              limited to fulfilling orders, processing payments, handling billing disputes and collections, analyzing
              data, providing marketing assistance and providing customer service. We may therefore share your
              personal data with such third parties. However, it is our policy not to sell or pass any personal data
              to any organizations outside LoanOnline for their marketing purposes without your consent.
            </div>
            <div className="team_text mt-3">
              LoanOnline may partner with third-party services for fraud prevention and/or identity verification.
              When you a load a page on LoanOnline that has a social plug-in from a third-party site or service,
              such as a Like or Send button, you are also loading content from that third-party site. That site
              may request cookies directly from your browser. These interactions are subject to the privacy
              policy of the third-party site.
            </div>
            <div className="team_text mt-3">
              The third parties referred to above include without limitation:
            </div>
            <div className="team_text mt-3">
              <ul>
                <li>LoanOnline’s partners, which include parties with whom LoanOnline collaborates with for
certain events, programs and activities;</li>
                <li>Merchants providing goods and services on the Website or the App and Page;</li>
                <li>Event management companies and event sponsors;</li>
                <li>Marketing research companies;</li>
                <li>Service providers, including, information technology (IT) service providers for
infrastructure, software and development work;</li>
                <li>Professional advisors and external auditors, including legal advisors, financial
advisors and consultants;</li>
                <li>Other entities within LoanOnline; and</li>
                <li>Government authorities to comply with statutory, regulatory and governmental
requirements.</li>
              </ul>
            </div>
            <div className="team_text mt-3">
              We can only speak for ourselves; this Policy does not apply to the practices of third parties that
              LoanOnline does not own or control, or individuals that LoanOnline does not employ or manage. If
              you provide your information to others on the Website or App and Page or throughout the Internet,
              different rules may apply to the use or disclosure of the information you provide to them.
              LoanOnline does not control the privacy policies of third parties, and you are subject to the privacy
              policies of those third parties where applicable. LoanOnline is not responsible for the privacy or
              security practices of other websites on the Internet, even those linked to or from the LoanOnline
              site. We encourage you to ask questions before you disclose your personal data to others.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Team;
