import React, { Component } from "react";
import { Form, Button, Modal, Table } from "react-bootstrap";
import Scroll from 'react-scroll'

import "react-calendar/dist/Calendar.css";

import Notification from "../../actions/notification";
import { InputText } from "../../component/InputText";

const Element = Scroll.Element;
const scroller = Scroll.scroller;

class LoanCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      interestRate: "",
      loanTermYear: "",
      loanTermMonth: 0,

      extraPayment: "",
      propertyTax: "",
      insurance: "",
      otherFee: "",
      pmi: "",
      pmiType: "",
      propertyPrice: "",

      hasResult: false,

      MonthlyPaymentWithPMI: "",
      MonthlyPMI: "",
      MonthlyPaymentWithOutPMI: "",
      TotalPayment: "",
      TotalInterest: "",
      AnnualPayment: "",
      MortgageConstant: "",
      InterestSaving: "",
      PayoffEarlierBy: "",


      showAmortization: false,

      LoanAmount: "",
      InterestRate: "",
      NumberOfMonths: "",
      MonthlyPayment: "",
      TotalPaid: "",

      Amortization: [],

      validated: false
    };
    this.calculate = this.calculate.bind(this);
    this.setValue = this.setValue.bind(this);
  }

  async calculate(event, showAmortization = false) {

    let k=this.state.amount
    k=k.replace(/\,/g,'');
    k=parseInt(k,10)
    if (!showAmortization) {
      event.preventDefault();
      event.stopPropagation();
    }

    let form = showAmortization ? event : event.currentTarget;
   
    if (form.checkValidity() === false) {
      Notification.Warn("Required", "Please fill all fields.")
    } else {
      let balance = k
        , interestRate = parseFloat(this.state.interestRate / 100.0)
        , terms = parseInt(this.state.loanTermYear * 12 + +this.state.loanTermMonth)
        , monthlyRate = interestRate / 12
        , monthltPayment = balance * (monthlyRate / (1 - Math.pow(1 + monthlyRate, -terms)))
        , amortization = [];

      this.setState({
        hasResult: true,
        LoanAmount: balance.toFixed(2),
        InterestRate: (interestRate * 100).toFixed(2),
        NumberOfMonths: terms,
        MonthlyPayment: monthltPayment.toFixed(2),
        TotalPaid: (monthltPayment * terms).toFixed(2)
      })

      // console.log("Loan amount: " + balance.toFixed(2))
      // console.log("Interest rate: " + (interestRate * 100).toFixed(2))
      // console.log("Number of months: " + terms)
      // console.log("Monthly payment: " + monthltPayment.toFixed(2))
      // console.log("Total paid: " + (monthltPayment * terms).toFixed(2))

      amortization.push([
        '',
        '',
        '',
        '',
        balance.toFixed(2)
      ])

      for (let count = 0; count < terms; ++count) {
        let interest = balance * monthlyRate
          , monthlyPrincipal = monthltPayment - interest;

        balance = balance - monthlyPrincipal

        if (balance < 0) {
          balance = 0
        }

        amortization.push([
          count + 1,
          monthltPayment.toFixed(2),
          interest.toFixed(2),
          monthlyPrincipal.toFixed(2),
          balance.toFixed(2)
        ])

      }

      this.setState({
        showAmortization: (showAmortization && amortization.length > 1) ? true : false,
        Amortization: amortization
      })

      scroller.scrollTo('myScrollToElement', {
        duration: 500,
        delay: 0,
        smooth: true,
        offset: -220
      })
    }

    this.setValue("validated", true)
  }

  setValue(state, value) {
    this.setState({
      [state]: value,
    })
  }

  formatNumber(value) {
    try {
      return value.toFixed(2).replace(/\.00$/, '')
    } catch (e) {
      return value
    }
  }

  render() {
    return (
      <div className="app__container">
        <div className="medium__text">Loan Calculator</div>

        <Form
          onSubmit={this.calculate}
          noValidate
          validated={this.state.validated}
        >
          <div className="input__group row">

            <div className="col-sm-12 col-md-4 col-lg-4">
              <InputText
                type="text"
                label="Enter Loan Amount"
                min={0}
                errorMessage={`Please Enter Loan Amount`}
                value={this.state.amount}
                setValue={(value) => this.setValue("amount", value)}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }}}
              />
            </div>
            {/* <div className="col-sm-12 col-md-3 col-lg-3">
              <Button variant="success">
                Calculate Loan Amount
              </Button>
            </div> */}
            <div className="col-sm-12 col-md-8 col-lg-8"></div>

            <div className="col-sm-12 col-md-4 col-lg-4">
              <InputText
                type="number"
                label="Enter Interest Rate"
                min={0}
                step={0.01}
                postpend="% per year"
                errorMessage={`Please Enter Interest Rate`}
                value={this.state.interestRate}
                setValue={(value) => this.setValue("interestRate", value)}
              />
            </div>
            <div className="col-sm-12 col-md-1 col-lg-1"></div>
            <div className="col-sm-12 col-md-2 col-lg-2">
              <InputText
                type="number"
                label="Enter Loan term"
                postpend="Years"
                step={1}
                min={10}
                max={30}
                errorMessage={`Please Enter Loan term year (Min:10, Max:30)`}
                value={this.state.loanTermYear}
                setValue={(value) => this.setValue("loanTermYear", value)}
              />
            </div>
            <div className="col-sm-12 col-md-2 col-lg-2">
              <InputText
                type="number"
                postpend="Months"
                step={1}
                min={0}
                max={+this.state.loanTermYear === 30 ? 0 : 11}
                errorMessage={`Please Enter Loan term month (Min:0, Max:${+this.state.loanTermYear === 30 ? 0 : 11})`}
                value={this.state.loanTermMonth}
                setValue={(value) => this.setValue("loanTermMonth", value)}
              />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3"></div>

            {/* <div className="col-sm-12 col-md-4 col-lg-4">
              <InputText
                type="number"
                label="Extra Payment a month"
                errorMessage={`Please Enter Extra Payment a month`}
                setValue={(value) => this.setValue("extraPayment", value)}
              />
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8"></div>

            <div className="col-sm-12 col-md-4 col-lg-4">
              <InputText
                type="number"
                label="Property tax"
                errorMessage={`Please Enter Property tax`}
                postpend="per Year"
                setValue={(value) => this.setValue("propertyTax", value)}
              />
            </div>
            <div className="col-sm-12 col-md-1 col-lg-1"></div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <InputText
                type="number"
                label="Insurance"
                errorMessage={`Please Enter Insurance`}
                postpend="per Year"
                setValue={(value) => this.setValue("insurance", value)}
              />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3"></div>

            <div className="col-sm-12 col-md-4 col-lg-4">
              <InputText
                type="number"
                label="Other Fee (HOA etc.)"
                errorMessage={`Please Enter Other Fee (HOA etc.)`}
                postpend="per Year"
                setValue={(value) => this.setValue("otherFee", value)}
              />
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8"></div>

            <div className="col-sm-12 col-md-4 col-lg-4">
              <InputText
                type="number"
                label="PMI"
                errorMessage={`Please Enter PMI`}
                postpend="%"
                setValue={(value) => this.setValue("pmi", value)}
              />
              <InputRadio
                key="pmi__option"
                id="pmi__option"
                options={
                  [
                    'Cancel PMI after balance drops to 80% of the purchase price',
                    'PMI for the life of loan'
                  ].map(data => {
                    return {
                      _id: data,
                      answer: data
                    }
                  })
                }
                setValue={(value) => this.setValue("pmiType", value)}
              />
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8"></div>

            <div className="col-sm-12 col-md-4 col-lg-4">
              <InputText
                type="number"
                label="Property price"
                errorMessage={`Please Enter Property price`}
                postpend="%"
                setValue={(value) => this.setValue("propertyPrice", value)}
              />
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8"></div> */}

          </div>

          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Button
                variant="outline-success"
                onClick={() => this.setValue('hasResult', false)}
              >
                Reset
              </Button>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Button
                variant="success highlight__button"
                onClick={(e) => this.calculate(document.getElementsByTagName('form')[0], true)}
              >
                Amortization
              </Button>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Button variant="success" type="submit">
                Calculate
              </Button>
            </div>
          </div>
        </Form>

        <Element name="myScrollToElement">
          {
            this.state.hasResult
            && <div className="row mt-4">
              <div className="col-sm-12 col-md-1 col-lg-1"></div>
              <div className="col-sm-12 col-md-10 col-lg-10 mt-4">
                <div className="card mt-4 mb-4 text-align-center">
                  {/* <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-4 __title right-align">Monthly Payment with PMI :</div>
                  <div className="col-sm-12 col-md-1 col-lg-1"></div>
                  <div className="col-sm-12 col-md-7 col-lg-7 __title__value left-align">{this.state.MonthlyPaymentWithPMI}</div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-4 __title right-align">Monthly PMI :</div>
                  <div className="col-sm-12 col-md-1 col-lg-1"></div>
                  <div className="col-sm-12 col-md-7 col-lg-7 __title__value left-align">{this.state.MonthlyPMI}</div>
                </div> */}
                  {/* <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-4 __title __theme__color left-align mt-2">After 0 Months :</div>
                </div> */}
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 __title right-align">Loan Amount :</div>
                    <div className="col-sm-12 col-md-1 col-lg-1"></div>
                    <div className="col-sm-12 col-md-7 col-lg-7 __title__value left-align">{this.state.LoanAmount}</div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 __title right-align">Interest Rate :</div>
                    <div className="col-sm-12 col-md-1 col-lg-1"></div>
                    <div className="col-sm-12 col-md-7 col-lg-7 __title__value left-align">{this.state.InterestRate}%</div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 __title right-align">Number of Months :</div>
                    <div className="col-sm-12 col-md-1 col-lg-1"></div>
                    <div className="col-sm-12 col-md-7 col-lg-7 __title__value left-align">{this.state.NumberOfMonths}</div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 __title right-align">Monthly Payment :</div>
                    <div className="col-sm-12 col-md-1 col-lg-1"></div>
                    <div className="col-sm-12 col-md-7 col-lg-7 __title__value left-align">{this.state.MonthlyPayment}</div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 __title right-align">Total Paid :</div>
                    <div className="col-sm-12 col-md-1 col-lg-1"></div>
                    <div className="col-sm-12 col-md-7 col-lg-7 __title__value left-align">{this.state.TotalPaid}</div>
                  </div>
                  {/* <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-4 __title __theme__color left-align mt-2">With Additional Payment</div>
                </div> */}
                  {/* <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-4 __title right-align">Interest Saving :</div>
                  <div className="col-sm-12 col-md-1 col-lg-1"></div>
                  <div className="col-sm-12 col-md-7 col-lg-7 __title__value left-align">{this.state.InterestSaving}</div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-4 __title right-align">Payoff Earlier By :</div>
                  <div className="col-sm-12 col-md-1 col-lg-1"></div>
                  <div className="col-sm-12 col-md-7 col-lg-7 __title__value left-align">{this.state.PayoffEarlierBy} Months</div>
                </div> */}
                </div>
              </div>
            </div>
          }
        </Element>

        <Modal
          show={this.state.showAmortization}
          onHide={() => this.setValue("showAmortization", false)}
          size="xl"
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Amortization
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped hover>
              <thead>
                <tr>
                  <th>Months</th>
                  <th>Monthly Payment</th>
                  <th>Interest</th>
                  <th>Principal</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.Amortization.map((d, i) => {
                    return (
                      <tr key={i}>
                        {
                          d.map((v, j) => {
                            return <td key={j}>{v}</td>
                          })
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>

      </div>
    );
  }
}
export default LoanCalculator;
