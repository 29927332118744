import React from "react"
import { withRouter } from 'react-router-dom'
import { Form, Button } from "react-bootstrap";

// import { RangeInput } from './../../component/RangeInput'
import { Title } from "./../../component/Title";
import { InputText } from "./../../component/InputText";
import { ImageInputSlider } from './../../component/ImageInputSlider'

import Notification from "../../actions/notification";
import BankAPI from './../../actions/bank'
import EMI from "./../../actions/emi";

class RefinancingCalculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            banks: [],

            selectedBankIndex1: 0,

            amountRange1: [80, 90, 100, 110, 120, 130, 140],
            interestRateRange1: [1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9],
            tenureRange1: [20, 30, 40, 50, 60, 70, 80],

            amount1: '',
            interest1: '',
            tenure: '',

            loanTermYear: 10,
            loanTermMonth: 0,

            selectedBankIndex2: 1,

            amountRange2: [80, 90, 100, 110, 120, 130, 140],
            interestRateRange2: [1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9],
            tenureRange2: [20, 30, 40, 50, 60, 70, 80],

            amount2: '',
            interest2: '',

            emi1: {
                interest: 0,
                installment: 0,
                payment: 0
            },

            emi2: {
                interest: 0,
                installment: 0,
                payment: 0
            }
        }

        this.selectBank1 = this.selectBank1.bind(this)
        this.selectBank2 = this.selectBank2.bind(this)
        this.setValue = this.setValue.bind(this)
        this.calculateEMI1 = this.calculateEMI1.bind(this)
        this.calculateEMI2 = this.calculateEMI2.bind(this)
        this.next = this.next.bind(this);
    }

    async componentDidMount() {
        this.props.showLoader(true)

        let banks = await BankAPI.getBanks()

        this.setState({
            banks: banks
        })
        this.props.showLoader(false)

        this.selectBank1(0)
        this.selectBank2(1)
    }

    selectBank1(index) {
        let bankDetails = this.state.banks[index]

        this.setState({
            selectedBankIndex1: index,

            interestRateRange1: Array
                .from({
                    length: (bankDetails["max_interest"] - bankDetails["min_interest"]) * 10 + 1,
                })
                .map((d, i) => +(bankDetails["min_interest"] + (i / 10)).toFixed(1)),

            tenureRange1: Array
                .from({
                    length: bankDetails["max_tenure"] - bankDetails["min_tenure"] + 1,
                })
                .map((d, i) => bankDetails["min_tenure"] + i),

            interest1: bankDetails["default_interest"],
            loanTermYear: bankDetails['default_tenure']
        })

        setTimeout(() => {
            this.calculateEMI1()
        }, 0)
    }

    selectBank2(index) {
        let bankDetails = this.state.banks[index]

        this.setState({
            selectedBankIndex2: index,

            interestRateRange2: Array
                .from({
                    length: (bankDetails["max_interest"] - bankDetails["min_interest"]) * 10 + 1,
                })
                .map((d, i) => +(bankDetails["min_interest"] + (i / 10)).toFixed(1)),

            tenureRange2: Array
                .from({
                    length: bankDetails["max_tenure"] - bankDetails["min_tenure"] + 1,
                })
                .map((d, i) => bankDetails["min_tenure"] + i),

            interest2: bankDetails['default_interest'],
            loanTermYear: bankDetails['default_tenure']
        })

        setTimeout(() => {
            this.calculateEMI2()
        }, 0)
    }

    setValue(state, value) {
        this.setState({
            [state]: value
        })

        setTimeout(() => {
            this.calculateEMI1()
            this.calculateEMI2()
        }, 0)
    }

    calculateEMI1() {
        let k=this.state.amount1
        k=k.replace(/\,/g,'');
        k=parseInt(k,10)
        this.setState({
            emi1: EMI.calculate(
                k,
                this.state.interest1,
                this.state.loanTermYear,
                this.state.loanTermMonth
            )
        })
    }

    calculateEMI2() {
        let k=this.state.amount2
        k=k.replace(/\,/g,'');
        k=parseInt(k,10)
        this.setState({
            emi2: EMI.calculate(
                k,
                this.state.interest2,
                this.state.loanTermYear,
                this.state.loanTermMonth
            )
        })
    }

    onNavClick() {
        window.scroll(0, 0)
    }

    next(event) {
        event.preventDefault();
        event.stopPropagation();
        let k=this.state.amount2
        k=k.replace(/\,/g,'');
        k=parseInt(k,10)
        let form = event.currentTarget;

        if (form.checkValidity() === false) {
            Notification.Warn("Required", "Please fill all fields.")
        } else {
            this.props.history.push("/loan", {
                amount1: k,
                bank1: this.state.banks[this.state.selectedBankIndex1]._id,
                interest1: this.state.interest1,
                amount2: this.state.amount2,
                bank2: this.state.banks[this.state.selectedBankIndex2]._id,
                interest2: this.state.interest2,
                tenure: +this.state.loanTermYear + (this.state.loanTermMonth / 12)
            })
        }

        this.setValue("validated", true)
    }

    render() {
        return (
            // (this.state.banks.length > 0) && 
            <div className="app__container">
                <Form
                    onSubmit={this.next}
                    noValidate
                    validated={this.state.validated}
                >
                    <div className="medium__text">Refinancing Monthly Installment Calculator</div>
                    <div className="row">
                        <div className="col-sm-12 col-md-5 col-lg-5">
                            {/* <RangeInput
                            title='Current Loan'
                            subtitle='(S$.)'
                            min={this.state.amountRange1[0]}
                            max={this.state.amountRange1[this.state.amountRange1.length - 1]}
                            range={this.state.amountRange1}
                            step={this.state.amountRange1[1] - this.state.amountRange1[0]}
                            symbol='K'
                            value={this.state.amount1}
                            setValue={(value) => this.setValue('amount1', value)}
                        /> */}
                            <Title
                                bigTitle="Current Loan"
                                subtitle="(S$.)"
                            />
                            <InputText
                                type="text"
                                label="Enter Loan Amount"
                                min={0}
                                errorMessage={`Please Enter Loan Amount`}
                                value={this.state.amount1}
                                setValue={(value) => this.setValue("amount1", value)}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }}}
                            />
                        </div>
                        <div className="col-sm-12 col-md-1 col-lg-1"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <ImageInputSlider
                                title='Current Bank'
                                images={this.state.banks}
                                value={this.state.selectedBankIndex1}
                                setValue={this.selectBank1}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-5 col-lg-5">
                            {/* <RangeInput
                            title='Current Interest Rate'
                            min={this.state.interestRateRange1[0]}
                            max={this.state.interestRateRange1[this.state.interestRateRange1.length - 1]}
                            range={this.state.interestRateRange1}
                            step={this.state.interestRateRange1[1] - this.state.interestRateRange1[0]}
                            symbol='%'
                            value={this.state.interest1}
                            setValue={(value) => this.setValue('interest1', value)}
                        /> */}
                            <Title
                                bigTitle="Current Interest Rate"
                                subtitle="(% per year)"
                            />
                            <InputText
                                type="number"
                                label="Enter Interest Rate"
                                step={0.01}
                                min={this.state.interestRateRange1[0]}
                                max={this.state.interestRateRange1[this.state.interestRateRange1.length - 1]}
                                errorMessage={`Please Enter Interest Rate (Min:${this.state.interestRateRange1[0]} , Max:${this.state.interestRateRange1[this.state.interestRateRange1.length - 1]} )`}
                                value={this.state.interest1}
                                setValue={(value) => this.setValue("interest1", value)}
                            />
                        </div>
                        <div className="col-sm-12 col-md-1 col-lg-1"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6"></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-5 col-lg-5">
                            {/* <RangeInput
                            title='Loan Amount'
                            subtitle='(S$.)'
                            min={this.state.amountRange2[0]}
                            max={this.state.amountRange2[this.state.amountRange2.length - 1]}
                            range={this.state.amountRange2}
                            step={this.state.amountRange2[1] - this.state.amountRange2[0]}
                            symbol='K'
                            value={this.state.amount2}
                            setValue={(value) => this.setValue('amount2', value)}
                        /> */}
                            <Title
                                bigTitle="Loan Amount"
                                subtitle="(S$.)"
                            />
                            <InputText
                                type="text"
                                label="Enter Loan Amount"
                                min={0}
                                errorMessage={`Please Enter Loan Amount`}
                                value={this.state.amount2}
                                setValue={(value) => this.setValue("amount2", value)}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }}}
                            />
                        </div>
                        <div className="col-sm-12 col-md-1 col-lg-1"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <ImageInputSlider
                                title='Select Bank'
                                images={this.state.banks}
                                value={this.state.selectedBankIndex2}
                                setValue={this.selectBank2}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-5 col-lg-5">
                            {/* <RangeInput
                            title='Interest Rate'
                            min={this.state.interestRateRange2[0]}
                            max={this.state.interestRateRange2[this.state.interestRateRange2.length - 1]}
                            range={this.state.interestRateRange2}
                            step={this.state.interestRateRange2[1] - this.state.interestRateRange2[0]}
                            symbol='%'
                            value={this.state.interest2}
                            setValue={(value) => this.setValue('interest2', value)}
                        /> */}
                            <Title
                                bigTitle="Interest Rate"
                                subtitle="(% per year)"
                            />
                            <InputText
                                type="number"
                                label="Enter Interest Rate"
                                step={0.01}
                                min={this.state.interestRateRange2[0]}
                                max={this.state.interestRateRange2[this.state.interestRateRange2.length - 1]}
                                errorMessage={`Please Enter Interest Rate (Min:${this.state.interestRateRange2[0]} , Max:${this.state.interestRateRange2[this.state.interestRateRange2.length - 1]} )`}
                                value={this.state.interest2}
                                setValue={(value) => this.setValue("interest2", value)}
                            />
                        </div>
                        <div className="col-sm-12 col-md-1 col-lg-1"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6"></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 center__group">
                            {/* <RangeInput
                            title='Loan Tenure'
                            subtitle='(Years)'
                            min={this.state.tenureRange2[0]}
                            max={this.state.tenureRange2[this.state.tenureRange2.length - 1]}
                            range={this.state.tenureRange2}
                            step={this.state.tenureRange2[1] - this.state.tenureRange2[0]}
                            symbol='Years'
                            hideSlider={true}
                            value={this.state.tenure}
                            setValue={(value) => this.setValue('tenure', value)}
                        /> */}
                            <Title
                                bigTitle="Loan Tenure"
                            />
                            <InputText
                                type="number"
                                postpend="Years"
                                step={1}
                                min={this.state.tenureRange1[0]}
                                max={this.state.tenureRange1[this.state.tenureRange1.length - 1]}
                                errorMessage={`Please Enter Loan term year (Min:${this.state.tenureRange1[0]} , Max:${this.state.tenureRange1[this.state.tenureRange1.length - 1]} )`}
                                value={this.state.loanTermYear}
                                setValue={(value) => this.setValue("loanTermYear", value)}
                            />
                            <InputText
                                type="number"
                                postpend="Months"
                                step={1}
                                min={0}
                                max={+this.state.loanTermYear === +this.state.tenureRange1[this.state.tenureRange1.length - 1] ? 0 : 11}
                                errorMessage={`Please Enter Loan term month (Min:0, Max:${+this.state.loanTermYear === +this.state.tenureRange1[this.state.tenureRange1.length - 1] ? 0 : 11})`}
                                value={this.state.loanTermMonth}
                                setValue={(value) => this.setValue("loanTermMonth", value)}
                            />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-sm-12 col-md-1 col-lg-1"></div>
                        <div className="col-sm-12 col-md-10 col-lg-10 mt-4">
                            <div className="card mt-4 mb-4">
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4">
                                        <div className="summary__image">
                                            {
                                                (
                                                    this.state.banks.length > 0
                                                    && this.state.banks[this.state.selectedBankIndex1]
                                                ) && (
                                                    <img src={this.state.banks[this.state.selectedBankIndex1].logo} alt='bank' />
                                                )
                                            }
                                        </div>
                                        <div className="__title">Monthly Installment</div>
                                        <div className="__title__value">S$. {this.state.emi1['installment']}</div>
                                        <div className="__title"> Interest Rate </div>
                                        <div className="__title__value"> {this.state.interest1} % </div>
                                        <div className="__title">Total Interest Payable</div>
                                        <div className="__title__value">S$. {this.state.emi1['interest']}</div>
                                        <div className="__title">
                                            Total Payment
                                    <span className="light__color"> (Principal + Interest)</span>
                                        </div>
                                        <div className="__title__value __theme__color">S$. {this.state.emi1['payment']}</div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4">
                                        <div className="summary__image">
                                            {
                                                (
                                                    this.state.banks.length > 0
                                                    && this.state.banks[this.state.selectedBankIndex2]
                                                ) && (
                                                    <img src={this.state.banks[this.state.selectedBankIndex2].logo} alt='bank' />
                                                )
                                            }
                                        </div>
                                        <div className="__title">Monthly Installment</div>
                                        <div className="__title__value">S$. {this.state.emi2['installment']}</div>
                                        <div className="__title"> Interest Rate </div>
                                        <div className="__title__value"> {this.state.interest2} % </div>
                                        <div className="__title">Total Interest Payable</div>
                                        <div className="__title__value">S$. {this.state.emi2['interest']}</div>
                                        <div className="__title">
                                            Total Payment
                                    <span className="light__color"> (Principal + Interest)</span>
                                        </div>
                                        <div className="__title__value __theme__color">S$. {this.state.emi2['payment']}</div>
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-4">
                                        <div className="summary__image"></div>
                                        <div className="__title">Savings with new Loan</div>
                                        <div className="__title__value __yellow__color">S$. {(this.state.emi1['payment'] - this.state.emi2['payment']).toFixed(2)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-1 col-lg-1"></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center mt-4">
                            <Button variant="success" type="submit"> Next </Button>
                        </div>
                    </div>
                </Form>
            </div >
        )
    }
}

export default withRouter(RefinancingCalculator)