import axios from "./../axios";

const addRemainderCalender = async (data) => {
  return axios({
    method: "POST",
    url: "/calendarReminder/add",
    data: data,
  })
    .then((response) => response.data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return [];
    });
};

let actions = {
  addRemainderCalender,
};

export default actions;
