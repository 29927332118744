import axios from '../axios';

const getPosts = async () => {
    try {
        return axios({
            method: 'GET',
            url: 'https://proplify.com/blog/wp-json/wp/v2/posts?categories=1'
        })
            .then(response => response.data)
            .then(response => {
                return response.map(res => {
                    return {
                        id: res['id'],
                        date: res['date'] ? new Date(res['date']).toDateString() : '',
                        title: res['title'] ? res['title']['rendered'] : '',
                        content: res['content'] ? res['content']['rendered'] : '',
                        featuredmedia: res['_links'] ? res['_links']['wp:featuredmedia'][0]['href'] : '',
                        image: '',
                        link: res['link']
                    }
                })
            })
            .catch(error => {
                return []
            })
    } catch (e) {
        return []
    }
}

const getPostMedia = async (url) => {
    try {
        return axios({
            method: 'GET',
            url: url
        })
            .then(response => response.data)
            .then(response => {
                return response.media_details.sizes['post-thumbnail']['source_url']
            })
            .catch(error => {
                return ''
            })

    } catch (e) {
        return ''
    }
}

let actions = {
    getPosts,
    getPostMedia
}

export default actions
