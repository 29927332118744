import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import callImage from "./../assets/images/call 1.svg"
import mailImage from "./../assets/images/mail 1.svg"
import pinImage from "./../assets/images/pin 2.svg"

import facebookImage from "./../assets/images/facebook 1.svg"
import instagramImage from "./../assets/images/instagram 1.svg"
import whatsappImage from "./../assets/images/whatsappImage.svg"
import phonecallImage from "./../assets/images/call.svg"

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  onClickHandler() {
    setTimeout(() => {
      window.scroll(0, 0)
    }, 0)
  }

  render() {
    return (
      <div className="footer">
        <div className="row">
          <div className="col-sm-12 col-md-5 col-lg-5">
            <div className="footer__title">Loan Online Mortgage Advisory</div>
            <div className="footer__text">
              SECURED LENDING | SEAMLESS EXPERIENCE
            </div>
          </div>
          <div className="col-sm-12 col-md-3 col-lg-3">
            <div className="footer__title">Site Map</div>
            <div className="footer_navlink">
              <NavLink
                className="footer__text__light"
                activeClassName="active__route"
                onClick={this.onClickHandler}
                to="/home"
              >
                Home
              </NavLink>
              <NavLink
                className="footer__text__light"
                to="/loan-calculator"
              >
                Calculator
              </NavLink>
              <NavLink
                className="footer__text__light sub__link"
                activeClassName="active__route"
                onClick={this.onClickHandler}
                to="/loan-calculator"
              >
                Loan Calculator
              </NavLink>
              <NavLink
                className="footer__text__light sub__link"
                activeClassName="active__route"
                onClick={this.onClickHandler}
                to="/loan-comparision-calculator"
              >
                Loan Comparision Calculator
              </NavLink>
              <NavLink
                className="footer__text__light"
                activeClassName="active__route"
                onClick={this.onClickHandler}
                to="/calendar"
              >
                Calendar
              </NavLink>
              <NavLink
                className="footer__text__light"
                activeClassName="active__route"
                onClick={this.onClickHandler}
                to="/team"
              >
                Our Team
              </NavLink>
              <NavLink
                className="footer__text__light"
                activeClassName="active__route"
                onClick={this.onClickHandler}
                to="/contact-us"
              >
                Contact Us
              </NavLink>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="footer__title">Find Us</div>
            <div className="footer_contact_box">
              <img className="contact_icon" src={pinImage} alt="pinImage" />
              <div className="footer__text__light">60, Paya Lebar Road, #12-14, Paya Lebar Square, Singapore 409051</div>
            </div>
            <div className="footer_contact_box">
              <img className="contact_icon" src={callImage} alt="callImage" />
              <div className="footer__text__light">+65 8300 2255</div>
            </div>
            <div className="footer_contact_box">
              <img className="contact_icon" src={mailImage} alt="mailImage" />
              <div className="footer__text__light">enquiry@loanonline.sg</div>
            </div>
          </div>
        </div>
        <hr />
        <div className="bottom_footer">
          <div className="footer_navlink">
            <NavLink
              className="footer__text__light mr-4"
              activeClassName="active__route"
              onClick={this.onClickHandler}
              to="/terms-of-use"
            >
              Terms of Use
            </NavLink>
            <NavLink
              className="footer__text__light mr-4"
              activeClassName="active__route"
              onClick={this.onClickHandler}
              to="/privacy-policy"
            >
              Privacy Policy
            </NavLink>
          </div>
          <div >
          <a style={{padding:'5px', opacity:'0.6'}} href="tel:+65 8300 2255" target="_blank" rel="noreferrer" className="social__icon">
              <img src={phonecallImage} alt="phonecallImage" width="40px"/> 
            </a>
          <a style={{padding:'5px', opacity:'0.5'}} href="https://api.whatsapp.com/send?phone=6583002255&text=Hello,%20How%20can%20I%20help%20you?" target="_blank" rel="noreferrer" className="social__icon">
              <img src={whatsappImage} alt="whatsappImage" width="40px"/>
            </a>
            <a style={{padding:'5px'}} href="https://www.facebook.com/loanonline.sg" target="_blank" rel="noreferrer" className="social__icon">
              <img src={facebookImage} alt="facebookImage" />
            </a>
            {/* <a style={{padding:'5px'}} href="https://www.facebook.com/loanonline.sg" target="_blank" rel="noreferrer" className="social__icon">
              <img src={facebookImage} alt="facebookImage" />
            </a> */}
            <a style={{padding:'5px'}} href="https://www.instagram.com/proplify.sg/" target="_blank" rel="noreferrer" className="social__icon">
              <img src={instagramImage} alt="instagramImage" />
            </a>
            
          </div>
        </div>
        <div>

          
        </div>
      </div>
    )
  }
}
