import React, { Component } from "react";

export class Title extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="__title">
        {this.props.bigTitle}
        {
          this.props.subtitle
          && <span> {this.props.subtitle}</span>
        }
      </div>
    )
  }
}
