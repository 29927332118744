import { store } from 'react-notifications-component'

const Success = async (title, message) => {
    addNotification('success', title, message)
}

const Warn = async (title, message) => {
    addNotification('warning', title, message)
}

const Error = async (title, message) => {
    addNotification('danger', title, message)
}

const addNotification = async (type, title, message) => {
    store.addNotification({
        title: title,
        message: message,
        type: type,
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 2000,
            onScreen: false
        }
    })
}

let actions = {
    Success,
    Warn,
    Error
}

export default actions
