import React, { Component } from "react"
// import ErwinImage from "./../assets/images/Erwin.png"
import ErwinImage from "./../assets/images/Erwin1.jpeg"

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="app__container">
        <div className="medium__text">Our Team </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-5">
            <div className="team__info mt-5">
              <img src={ErwinImage} alt="ErwinImage" width="250"></img>
              <div className="team__title mt-4">Erwin</div>
              <div className="team__subtitle mt-3">
                Chief Mortgage Consultant, LOL Interactive Pte. Ltd.
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-7">
            <div className="team_text mt-5" >
              Erwin started LoanOnLine.sg Mortgage Advisory (LOL Interactive Pte
              Ltd) in September 2018 and has since guided LOL in his role as
              Chief Mortgage Consultant (Director).
            </div>
            <div className="team_text mt-3">
              Prior to leading this team at LOL, Erwin worked with various banks
              specialising in Mortgage Secured Lending and directed a mortgage
              brokerage firm.
            </div>
            <div className="team_text mt-3">
              His vast experience of 12 years in mortgage advisory has led him
              to numerous clients as he has proven to be able to meet their
              requirements with the right type of advice and deep knowledge on
              home loan processes. His clients never walk away without cost
              savings and a lower interest rate on their home loans.
            </div>
            <div className="team_text mt-3">
              During his banking tenure, Erwin managed to independently aid the
              banking organisations in achieving a transactional volume of over
              SGD900 million. Through his leadership, Erwin also brought in
              projects with transactions of over SGD1 billion together with his
              team.
            </div>
            <div className="team_text mt-3">
              Due to his strong support and service to clients, Erwin was
              awarded numerous Best Customer Experience in Bank and Service
              Award under the Excellent Service Award (EXSA) National.
            </div>
            <div className="team_text mt-3">
              Having worked in both local and foreign banks in Singapore for 12
              years, Erwin will bring this invaluable banking experience and
              network with him to the current and prospective pool of clientele
              that engages his service and ensure that the best home loans and
              clients’ requirements are met.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Team;
