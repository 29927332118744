import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Card } from "react-bootstrap";

import CalendarPage from './Calender'
import imagePlaceholder from "./../logo.svg";

// import shieldImage from "./../assets/images/shield.svg"
// import scaleImage from "./../assets/images/scale.svg"
// import trophyImage from "./../assets/images/trophy.svg"
// import magicWandImage from "./../assets/images/magic-wand.svg"
// import researchImage from "./../assets/images/research.svg"

import profitsImage from "./../assets/images/profits.svg"
import buildingImage from "./../assets/images/building.svg"
import earthGlobeImage from "./../assets/images/earth-globe.svg"
import moneyBagImage from "./../assets/images/money-bag.svg"
import checkedImage from "./../assets/images/checked.svg"
import balanceImage from "./../assets/images/balance.svg"
import constructionImage from "./../assets/images/construction.svg"
import houseImage from "./../assets/images/house.svg"

// import apartmentImage from "./../assets/images/modern-apartment-architecture 1.png"
// import userImage from "./../assets/images/user.png"
// import starFillImage from "./../assets/images/star-fill.svg"
// import starBlankImage from "./../assets/images/star-blank.svg"

import bank1Image from "./../assets/images/bank/Bank_Of_China_logo.png"
import bank2Image from "./../assets/images/bank/CIMB-Logo.png"
import bank3Image from "./../assets/images/bank/Citibank_logo.png"
import bank4Image from "./../assets/images/bank/DBS_Bank_logo_logotype.png"
import bank5Image from "./../assets/images/bank/Hong Leong Finance.png"
import bank6Image from "./../assets/images/bank/hsbc-logo-5.png"
import bank7Image from "./../assets/images/bank/ICBC.png"
import bank8Image from "./../assets/images/bank/maybank-logo-vector.svg"
import bank9Image from "./../assets/images/bank/OCBC_Bank_logo_logotype_Singapore.png"
import bank10Image from "./../assets/images/bank/RHB.png"
import bank11Image from "./../assets/images/bank/SBI.png"
import bank12Image from "./../assets/images/bank/sc-lock-up-english-grey-rgb.png"
import bank13Image from "./../assets/images/bank/Singapura Finance Limited.png"
import bank14Image from "./../assets/images/bank/UOB_United_Overseas_Bank_logo_logotype_symbol.png"

// import media1Image from "./../assets/images/EnjoyCompare 1.png"
// import media2Image from "./../assets/images/iproperty-com-sg-seeklogo.com 2.png"

import BlogAPI from "./../actions/blog";
import ReactPlayer from "react-player";
import Calendar from "react-calendar";


const RenderHTML = (props) => (<span dangerouslySetInnerHTML={{ __html: props.HTML }}></span>)

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: []
    }

    this.getPosts = this.getPosts.bind(this)
  }

  componentDidMount() {
    document.getElementById('App').classList.add('home__with__bg')
    this.getPosts()
  }

  async getPosts() {
    let posts = await BlogAPI.getPosts()
    if (posts) {
      this.setState({
        posts: posts
      })
      for (let p of posts) {
        if (p['featuredmedia']) {
          let image = await BlogAPI.getPostMedia(p['featuredmedia'])
          if (image) {
            p['image'] = image
          }
        }
      }
      this.setState({
        posts: posts
      })
      console.log(this.state.posts)
    }
  }

  componentWillUnmount() {
    document.getElementById('App').classList.remove('home__with__bg')
  }


  render() {

    return (
      <div className="app__container home__page">
        <div className="row with__bg">
          <div className="col-sm-12 col-md-6 col-lg-6 left__home">
            <div className="mt-4">
              <div className="xlarge__text"> Secured Lending </div>
              <div className="xlarge__text"> Seamless Experience </div>
              <div className="card mt-4 text-center">
                <div className="small__text">Your Mortgage Journey Starts</div>
                <div className="small__text">here</div>
                <div className="medium__text m-4">Your Loan Type :</div>
                <NavLink to="/calculators/new_loan">
                  <button className="btn btn-success">New Loan</button>
                </NavLink>
                <NavLink to="/calculators/refinancing">
                  <button className="btn btn-success">Refinancing</button>
                </NavLink>
                <NavLink className="link mt-4" to="/contact-us">
                  <span> Contact Us </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-arrow-right-circle-fill ml-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                  </svg>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 right__home">
          </div>
        </div>

        <div className="home__container">
          <CalendarPage showLoader={this.props.showLoader} />
        </div>

        <div className="home__container">
          <div className="home__title">Why LoanOnline?</div>
          {/* <div className="home__image__container">
            <div className="home__image__box">
              <img className="home__image" src={shieldImage} alt="shieldImage"></img>
              <div className="home__image__small__title">Secured Environment</div>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={scaleImage} alt="scaleImage"></img>
              <div className="home__image__small__title">Unbiased Advisory</div>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={trophyImage} alt="trophyImage"></img>
              <div className="home__image__small__title">Service Excellence</div>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={magicWandImage} alt="magicWandImage"></img>
              <div className="home__image__small__title">Delivery Simplification</div>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={researchImage} alt="researchImage"></img>
              <div className="home__image__small__title">Extensive Research</div>
            </div>
          </div> */}
          <div className="home__small__text">
            LoanOnLine (LOL) Mortgage Advisory is an independent, professional mortgage advisory firm that helps home owners with their property financing needs. Whether you’re a HDB owner, planning to buy a private property, or looking to refinance your current property to unlock savings and value, you can always rely on LOL to provide prudent and transparent rates that are compared across all banks in Singapore.
          </div>
          <div className="home__small__text">
            A cut above the rest, LOL prides itself on being a one-stop home financing concierge solution that delivers exemplary service to its customers. True to our tagline — “Secured Lending, Seamless Experience”, LOL is committed to add the greatest value to your property financing needs.
          </div>

        </div>
        <div className="home__container">
          <ReactPlayer
            url="https://youtu.be/GdH_SPmojxI"
            controls={false}
            width='100%'
            pointer-events='none'
            loop={true}
            light={true}
            volume={null}
            muted={false}
            playbackRate={1}

          />
          <div className="home__container home__title">
            <div className="home__title">Follow Us For Updates</div>
            <div class="fb-page center" data-href="https://www.facebook.com/loanonline.sg" data-tabs="timeline" data-width="500" data-height="500" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
              <blockquote cite="https://www.facebook.com/loanonline.sg" class="fb-xfbml-parse-ignore">
                <a href="https://www.facebook.com/loanonline.sg">LoanOnline</a></blockquote></div>
          </div>
        </div>
        <div className="home__container">
          <div className="home__title">Our Services</div>
          <div className="home__image__container">
            <div className="home__image__box">
              <img className="home__image" src={checkedImage} alt="checkedImage"></img>
              {/* <div className="home__image__medium__title"> Get a Loan Eligibility Check</div> */}
              <div className="home__image__medium__title">Loan Eligibility Check</div>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={houseImage} alt="houseImage"></img>
              <div className="home__image__medium__title">Residential Property Home Loan</div>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={buildingImage} alt="buildingImage"></img>
              <div className="home__image__medium__title">Commercial Property Loan</div>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={constructionImage} alt="constructionImage"></img>
              <div className="home__image__medium__title">Home Construction Loan</div>
            </div>
          </div>
          <div className="home__image__container">
            <div className="home__image__box">
              <img className="home__image" src={profitsImage} alt="profitsImage"></img>
              <div className="home__image__medium__title">Equity Loan</div>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={moneyBagImage} alt="moneyBagImage"></img>
              <div className="home__image__medium__title">Business Loan</div>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={earthGlobeImage} alt="earthGlobeImage"></img>
              {/* <div className="home__image__medium__title">International Home Loan</div> */}
              <div className="home__image__medium__title">Foreign Property Loan</div>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={balanceImage} alt="balanceImage"></img>
              <div className="home__image__medium__title">Bridging Loan</div>
            </div>
          </div>
        </div>

        <div className="home__container">
          <div className="home__title">Latest Blog</div>
          <div className="row">
            {
              this.state.posts.map((post, index) => {
                return (
                  <div key={index} className="col-sm-12 col-md-4 col-lg-4">
                    <Card
                      onClick={() => window.open(post.link, "_blank")}
                    >
                      <div className="img-hover-zoom">
                        <Card.Img
                          variant="top"
                          src={post.image ? post.image : imagePlaceholder}
                          className={post.image ? '' : 'gray_bg'}
                        />
                      </div>
                      <Card.Body>
                        <Card.Subtitle>{post.date}</Card.Subtitle>
                        <Card.Title>{post.title}</Card.Title>
                        <Card.Text>
                          <RenderHTML HTML={post.content} />
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                )
              })
            }
            {/* <div className="col-sm-12 col-md-4 col-lg-4">
              <Card>
                <Card.Img variant="top" src={apartmentImage} />
                <Card.Body>
                  <Card.Subtitle>April 14, 2021</Card.Subtitle>
                  <Card.Title>What is the best home loan in Singapore in 2021 ?</Card.Title>
                  <Card.Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas faucibus nulla sed lacus vulputate efficitur. Vestibulum vel ornare eros...
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Card>
                <Card.Img variant="top" src={apartmentImage} />
                <Card.Body>
                  <Card.Subtitle>April 14, 2021</Card.Subtitle>
                  <Card.Title>What is the best home loan in Singapore in 2021 ?</Card.Title>
                  <Card.Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas faucibus nulla sed lacus vulputate efficitur. Vestibulum vel ornare eros...
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Card>
                <Card.Img variant="top" src={apartmentImage} />
                <Card.Body>
                  <Card.Subtitle>April 14, 2021</Card.Subtitle>
                  <Card.Title>What is the best home loan in Singapore in 2021 ?</Card.Title>
                  <Card.Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas faucibus nulla sed lacus vulputate efficitur. Vestibulum vel ornare eros...
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8">
              <Card>
                <Card.Img variant="top" src={apartmentImage} />
                <Card.Body>
                  <Card.Subtitle>April 14, 2021</Card.Subtitle>
                  <Card.Title>What is the best home loan in Singapore in 2021 ?</Card.Title>
                  <Card.Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas faucibus nulla sed lacus vulputate efficitur. Vestibulum vel ornare eros...
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Card>
                <Card.Img variant="top" src={apartmentImage} />
                <Card.Body>
                  <Card.Subtitle>April 14, 2021</Card.Subtitle>
                  <Card.Title>What is the best home loan in Singapore in 2021 ?</Card.Title>
                  <Card.Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas faucibus nulla sed lacus vulputate efficitur. Vestibulum vel ornare eros...
                  </Card.Text>
                </Card.Body>
              </Card>
            </div> */}
          </div>
        </div>

        {/* <div className="home__container">
          <div className="home__title">What our Customers Say</div>
          <div className="row">
            {
              Array.from({ length: 8 })
                .map((_, i) => {
                  return (
                    <div key={i} className="col-sm-12 col-md-6 col-lg-6" >
                      <div className="rating_box">
                        <div className="rating_image">
                          <img src={userImage} alt="userImage"></img>
                        </div>
                        <div className="rating_details">
                          <div className="rating__title">John Doe</div>
                          <div className="rating__star">
                            <span>a day ago</span>
                            <span>
                              <img src={starFillImage} alt="starFillImage"></img>
                              <img src={starFillImage} alt="starFillImage"></img>
                              <img src={starFillImage} alt="starFillImage"></img>
                              <img src={starFillImage} alt="starFillImage"></img>
                              <img src={starBlankImage} alt="starBlankImage"></img>
                            </span>
                          </div>
                          <div className="rating__text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
            } </div>
        </div> */}

        <div className="home__container">
          <div className="home__title">Bank Partners</div>
          <div className="home__image__container">
            <div className="home__image__box">
              <img className="home__image" src={bank1Image} alt="bank1Image"></img>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={bank2Image} alt="bank2Image"></img>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={bank3Image} alt="bank3Image"></img>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={bank4Image} alt="bank4Image"></img>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={bank5Image} alt="bank5Image"></img>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={bank6Image} alt="bank6Image"></img>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={bank7Image} alt="bank7Image"></img>
            </div>
          </div>
          <div className="home__image__container">
            <div className="home__image__box">
              <img className="home__image" src={bank8Image} alt="bank8Image"></img>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={bank9Image} alt="bank9Image"></img>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={bank10Image} alt="bank10Image"></img>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={bank11Image} alt="bank11Image"></img>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={bank12Image} alt="bank12Image"></img>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={bank13Image} alt="bank13Image"></img>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={bank14Image} alt="bank14Image"></img>
            </div>
          </div>
        </div>

        {/* <div className="home__container">
          <div className="home__title">Media Partners</div>
          <div className="home__image__container">
            <div className="home__image__box">
              <img className="home__image" src={media1Image} alt="media1Image"></img>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={media2Image} alt="media2Image"></img>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={media1Image} alt="media1Image"></img>
            </div>
            <div className="home__image__box">
              <img className="home__image" src={media2Image} alt="media2Image"></img>
            </div>
          </div>
        </div> */}

      </div >
    )
  }
}
