import React from "react";
import { withRouter } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

// import { RangeInput } from "./../../component/RangeInput";
import { Title } from "./../../component/Title";
import { InputText } from "./../../component/InputText";
import { ImageInputSlider } from "./../../component/ImageInputSlider";

import Notification from "../../actions/notification";
import BankAPI from "./../../actions/bank";
import EMI from "./../../actions/emi";

class NewLoanCalculator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      banks: [],

      selectedBankIndex: 0,

      amountRange: [80, 90, 100, 110, 120, 130, 140],
      interestRateRange: [1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9],
      tenureRange: [20, 30, 40, 50, 60, 70, 80],

      amount: '',
      interest: '',
      tenure: '',
      loanTermYear: 10,
      loanTermMonth: 0,

      emi: {
        interest: 0,
        installment: 0,
        payment: 0,
      },
    };

    this.selectBank = this.selectBank.bind(this);
    this.setValue = this.setValue.bind(this);
    this.calculateEMI = this.calculateEMI.bind(this);
    this.next = this.next.bind(this);
  }

  async componentDidMount() {
    this.props.showLoader(true);

    let banks = await BankAPI.getBanks();

    this.setState({
      banks: banks
    });
    this.props.showLoader(false);

    this.selectBank(0);
  }

  selectBank(index) {
    let bankDetails = this.state.banks[index];

    this.setState({
      selectedBankIndex: index,

      interestRateRange: Array
        .from({
          length: (bankDetails["max_interest"] - bankDetails["min_interest"]) * 10 + 1,
        })
        .map((d, i) => +(bankDetails["min_interest"] + (i / 10)).toFixed(1)),

      tenureRange: Array
        .from({
          length: bankDetails["max_tenure"] - bankDetails["min_tenure"] + 1,
        })
        .map((d, i) => bankDetails["min_tenure"] + i),

      interest: bankDetails["default_interest"],
      loanTermYear: bankDetails["default_tenure"],
    });

    setTimeout(() => {
      this.calculateEMI();
    }, 0);
  }

  setValue(state, value) {
    this.setState({
      [state]: value,
    });

    setTimeout(() => {
      this.calculateEMI();
    }, 0);
  }

  calculateEMI() {
    let k=this.state.amount
    k=k.replace(/\,/g,'');
    k=parseInt(k,10)
    this.setState({
      emi: EMI.calculate(
        k,
        this.state.interest,
        this.state.loanTermYear,
        this.state.loanTermMonth
      )
    })
  }

  onNavClick() {
    window.scroll(0, 0);
  }

  next(event) {
    event.preventDefault();
    event.stopPropagation();
    let k=this.state.amount
    k=k.replace(/\,/g,'');
    k=parseInt(k,10)


    let form = event.currentTarget;

    if (form.checkValidity() === false) {
      Notification.Warn("Required", "Please fill all fields.")
    } else {
      this.props.history.push("/loan", {
        amount1: k,
        bank1: this.state.banks[this.state.selectedBankIndex]._id,
        interest1: this.state.interest,
        tenure: +this.state.loanTermYear + (this.state.loanTermMonth / 12)
      })
    }

    this.setValue("validated", true)
  }


  render() {
    return (
      // this.state.banks.length > 0 && (
      <div className="app__container">
        <Form
          onSubmit={this.next}
          noValidate
          validated={this.state.validated}
        >
          <div className="medium__text">New Loan Monthly Installment Calculator</div>
          <div className="row">
            <div className="col-sm-12 col-md-5 col-lg-5">
              {/* <RangeInput
                title="Loan Amount"
                subtitle="(S$.)"
                min={this.state.amountRange[0]}
                max={this.state.amountRange[this.state.amountRange.length - 1]}
                range={this.state.amountRange}
                step={this.state.amountRange[1] - this.state.amountRange[0]}
                symbol="K"
                value={this.state.amount}
                setValue={(value) => this.setValue("amount", value)}
              /> */}
              <Title
                bigTitle="Loan Amount"
                subtitle="(S$.)"
              />
              <InputText
                type="text"
                label="Enter Loan Amount"
                min={0} 
                errorMessage={`Please Enter Loan Amount`}
                value={this.state.amount}
                setValue={(value) => this.setValue("amount", value)}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }}}
                 
              />
            </div>
            <div className="col-sm-12 col-md-1 col-lg-1"></div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <ImageInputSlider
                title="Select Bank"
                images={this.state.banks}
                value={this.state.selectedBankIndex}
                setValue={this.selectBank}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-5 col-lg-5">
              {/* <RangeInput
                title="Interest Rate"
                min={this.state.interestRateRange[0]}
                max={
                  this.state.interestRateRange[
                  this.state.interestRateRange.length - 1
                  ]
                }
                range={this.state.interestRateRange}
                step={
                  this.state.interestRateRange[1] -
                  this.state.interestRateRange[0]
                }
                symbol="%"
                value={this.state.interest}
                setValue={(value) => this.setValue("interest", value)}
              /> */}
              <Title
                bigTitle="Interest Rate"
                subtitle="(% per year)"
              />
              <InputText
                type="number"
                label="Enter Interest Rate"
                step={0.01}
                min={this.state.interestRateRange[0]}
                max={this.state.interestRateRange[this.state.interestRateRange.length - 1]}
                errorMessage={`Please Enter Interest Rate (Min:${this.state.interestRateRange[0]} , Max:${this.state.interestRateRange[this.state.interestRateRange.length - 1]} )`}
                value={this.state.interest}
                setValue={(value) => this.setValue("interest", value)}
              />
            </div>
            <div className="col-sm-12 col-md-1 col-lg-1"></div>
            {/* <div className="col-sm-12 col-md-6 col-lg-6">
              <RangeInput
                title="Loan Tenure"
                subtitle="(Years)"
                min={this.state.tenureRange[0]}
                max={this.state.tenureRange[this.state.tenureRange.length - 1]}
                range={this.state.tenureRange}
                step={this.state.tenureRange[1] - this.state.tenureRange[0]}
                symbol="Years"
                hideSlider={true}
                value={this.state.tenure}
                setValue={(value) => this.setValue("tenure", value)}
              />
            </div> */}
            <div className="col-sm-12 col-md-4 col-lg-4 row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <Title
                  bigTitle="Loan Tenure"
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <InputText
                  type="number"
                  bigTitle="Loan Tenure"
                  label="Enter Loan term"
                  postpend="Years"
                  step={1}
                  min={this.state.tenureRange[0]}
                  max={this.state.tenureRange[this.state.tenureRange.length - 1]}
                  errorMessage={`Please Enter Loan term year (Min:${this.state.tenureRange[0]} , Max:${this.state.tenureRange[this.state.tenureRange.length - 1]} )`}
                  value={this.state.loanTermYear}
                  setValue={(value) => this.setValue("loanTermYear", value)}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <InputText
                  type="number"
                  step={1}
                  min={0}
                  max={+this.state.loanTermYear === +this.state.tenureRange[this.state.tenureRange.length - 1] ? 0 : 11}
                  errorMessage={`Please Enter Loan term month (Min:0, Max:${+this.state.loanTermYear === +this.state.tenureRange[this.state.tenureRange.length - 1] ? 0 : 11})`}
                  postpend="Months"
                  value={this.state.loanTermMonth}
                  setValue={(value) => this.setValue("loanTermMonth", value)}
                />
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-12 col-md-6 col-lg-6 mt-4">
              <div className="card mt-4 mb-4">
                <div className="row">
                  <div className="col-8">
                    <div className="__title">Monthly Installment</div>
                    <div className="__title__value">
                      S$. {this.state.emi["installment"]}
                    </div>
                    <div className="__title">Total Interest Payable</div>
                    <div className="__title__value">
                      S$. {this.state.emi["interest"]}
                    </div>
                  </div>
                  <div className="col-4 d-flex summary__image">
                    {
                      (this.state.banks.length > 0) && (
                        <img
                          src={this.state.banks[this.state.selectedBankIndex].logo}
                          alt="bank"
                        />
                      )
                    }
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="__title">
                      Total Payment
                      <span className="light__color">
                        {" "}
                        (Principal + Interest)
                      </span>
                    </div>
                    <div className="__title__value __theme__color">
                      S$. {this.state.emi["payment"]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center mt-4">
              <Button variant="success" type="submit"> Next </Button>
            </div>
          </div>
        </Form>
      </div>
      // )
    );
  }
}

export default withRouter(NewLoanCalculator);
