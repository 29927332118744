import React, { Component } from "react"

class PDPATermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="app__container">
        <div className="medium__text">Enquiry Terms and Conditions</div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="team_text mt-5">
              I consent to the disclosure and transfer of my personal data as completed above to LoanOnline (LOL
              Interactive Pte Ltd), for the purpose of enabling LoanOnline to provide me with the services required
              of a mortgage broker services provider including but not limited to evaluating, processing,
              administering and/or managing of my relationship and/or products.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PDPATermsAndConditions;
