import React, { Component } from "react";
import { Form } from "react-bootstrap";

export class InputSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Form.Group controlId="exampleForm.SelectCustom">
        {this.props.title && (
          <div className="formgroup__title">{this.props.title}</div>
        )}
        {this.props.label && <Form.Label>{this.props.label}</Form.Label>}
        <Form.Control
          as="select"
          custom
          value={this.props.value}
          onChange={(e) => this.props.setValue(e.target.value)}
        >
          <option disabled>
            Select Period
          </option>
          {
            this.props.options
              .map((d, i) => {
                return <option key={i}>{d.val}</option>
              })
          }
        </Form.Control>
      </Form.Group>
    );
  }
}
