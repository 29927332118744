import axios from '../axios';

const getQuestions = async () => {
    return axios({
        method: 'POST',
        url: '/mortgageLoanQuestion'
    })
        .then(response => response.data)
        .then(response => {
            return response.data.response
        })
        .catch(error => {
            return []
        })
}

const sendLead = async (data, other) => {
    return axios({
        method: 'POST',
        url: '/lead/add',
        data: {
            questions: data,
            other: other
        }
    })
        .then(response => {
            return response.data
        })
        .catch(error => {
            return []
        })
}

let actions = {
    getQuestions,
    sendLead
}

export default actions
