import axios from '../axios';

const send = async (data) => {
    return axios({
        method: 'POST',
        url: '/contactUs',
        data: data
    })
        .then(response => response.data)
        .then(response => {
            return response
        })
        .catch(error => {
            return {
                message: 'Something Wrong!'
            }
        })
}

let actions = {
    send
}

export default actions
